.header {
    position: fixed;
    width: 100%;
    padding: 50px 0;
    z-index: 100;
    transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);
}

.headerScrolled {
    background: rgba(0, 0, 0, 0.8);
    padding: 15px 0;
    backdrop-filter: blur(20px);
}

.headerGroup {
    max-width: 800px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(5, auto);
    align-items: center;
    justify-items: center;
}

.header a {
    color: white;
    font-weight: 700;
}

.header .brand {
    transition: 1.5s cubic-bezier(0.2, 0.8, 0.2, 1);
    transform: scale(1.3, 1.3);
}

.header .brand:hover {
    transform: scale(1.6, 1.6);
}

.header button {
    padding: 8px 20px;
    font-size: 20px;
    background: #56CCF2;
    border: none;
    font-weight: 700;
    border-radius: 10px;
    outline: none;
    cursor: pointer;
    transition: 1s cubic-bezier(0.2, 0.8, 0.2, 1);
}

.header button:hover {
    background: white;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.25);
    transform: translateY(-3px);
}

@media (max-width: 640px) {
    .header {
        padding: 15px 0;
    }

    .headerGroup {
        grid-template-columns: repeat(4, auto);
    }

    .header a:nth-child(4) {
        display: none;
    }
}

