body {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    margin: 0;
    font-size: 20px;
    -webkit-font-smooting: antialiased;
    line-height: 1.3;
}

a {
    color: #5334F5;
    text-decoration: none;
    font-weight: 600;
}

.hero {
    background: url(./images/wallpaper3.jpg);
    height: 920px;
    background-size: cover;
    background-position: center;
    position: relative;
}

.heroGroup {
    margin: 0 auto;
    max-width: 500px;
    padding: 150px 50px;
    text-align: center;
}

.hero h1 {
    margin: 0;
    color: white;
    font-size: 60px;
    line-height: 1.2;
    opacity: 0;
    animation: HeroAnimation;
    animation-duration: 3s;
    animation-delay: 0.1s;
    animation-fill-mode: forwards;
    animation-timing-function: cubic-bezier(0.2, 0.8, 0.2, 1);
}
  
.hero p {
    color: rgba(255, 255, 255, 0.8);
    font-size: 30px;
    line-height: 1.5;
    animation: HeroAnimation 3s 0.2s forwards cubic-bezier(0.2, 0.8, 0.2, 1);
    opacity: 0;
}
  
.hero .videoBtn {
    font-size: 17px;
    font-weight: 600;
    color: white;
    text-transform: uppercase;
    background: rgba(0, 0, 0, 0.7);
    padding: 9px 20px;
    border: 1px solid rgba(255, 255, 255, 0.25);
    border-radius: 20px;
}
  
.hero .videoBtn:hover {
    background: white;
    color: black;
    transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);
}
  
@media (max-width: 640px) {
    .heroGroup {
        padding: 100px, 20px;
    }
    
    .hero h1 {
        font-size: 40px;
    }
  
    .hero p {
        font-size: 24px;
    }
}
  
@keyframes HeroAnimation {
    0% {
        opacity: 0;
        transform: translateY(20px);
    }
    100% {
        opacity: 1;
        transform: translateY(0px);
    }
}

.hero .logos {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-gap: 40px;
    margin: 100px 0;
    justify-items: center;
    transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);
}
  
.hero .logos img {
    transition: 0.4s cubic-bezier(0.2, 0.8, 0.2, 1);
}
  
.hero .logos:hover img {
    filter: blur(8px)
}
  
.hero .logos img:hover {
    filter: blur(0px);
    transform: scale(1.5, 1.5)
}
  
@media (max-width: 640px) {
    .hero .logos {
        grid-template-columns: repea  (3, 1fr);
    }
}

.hero svg {
    position: absolute;
    bottom: 0;
    left: 0;
  }

.cards h2 {
    margin: 50px 20px;
    font-size: 60px;
    text-align: center;
    font-weight: 700;
    background: linear-gradient(104deg, #050A27 0%, #4A548C 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.cardGroup {
    margin: 50px 40px 100px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 40px;
    justify-items: center;
}
  
@media (max-width: 1060px) {
    .cardGroup {
        grid-template-columns: repeat(2, 1fr);
    }
}
  
@media (max-width: 720px) {
    .cardGroup {
        grid-template-columns: repeat(1, 1fr);
    }
}